.team {
  display: flex;
  width: auto;
  padding: 136px 52px 33px 52px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 48px; */
  background: radial-gradient(101.43% 101.43% at 50% 0%, #f1f4ec 0%, #fff 100%);
}
.team-heading-container {
  display: flex;
  padding: 0px 52px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: rgba(255, 255, 255, 0);
}
.team-heading {
  display: flex;
  width: auto;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}
.heading-text {
  color: #282828;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  align-self: stretch;
}
.team-subheading {
  color: #282828;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 54px;
  font-style: normal;
  font-weight: 600;
  line-height: 62px;
  letter-spacing: -1px;
}
.team-para {
  width: 760px;
}
.para-text {
  color: #5f6980;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px; /* 175% */
}
.team-cards {
  display: flex;
  width: auto;
  padding: 96px 52px 33px 52px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 48px;
}
.cards-container {
  display: flex;
  width: auto;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
}
.cards-container-row-1 {
  display: flex;
  width: auto;
  align-items: flex-start;
  gap: 32px;
}
.card-r-1 {
  display: flex;
  width: auto;
  height: 450px;
  padding-bottom: 0px;
  flex-direction: column;
  gap: 16px;
  border: 1px solid #eaecf0;
  background: #fff;
}
.card-container-r-1 {
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}
.card-faq-page {
  width: 230px;
  height: 230px;
  background: lightgray 50% / cover no-repeat;
}
.card-container-text {
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.card-container-text-heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.card-heading {
  align-self: stretch;
  color: #282828;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 166.667% */
}
.card-subheading {
  align-self: stretch;
  color: #3e3e59;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.card-para {
  color: #5f6980;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.card-social-icons {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  margin-left: 10px;
}
.card-icons {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.card-icon-1 {
  display: flex;
  width: 36px;
  height: 36px;
  padding: 9px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #f2f4f7;
}
@media (max-width: 320px) {
  .team-cards {
    padding: 56px 0px 33px 0px;
    gap: 48px;
  }
  .team-heading-container {
    gap: 10px;
  }
  .heading-text {
    font-size: 15px;
  }
  .team-subheading {
    font-size: 45px;
    width: 300px;
  }
  .team-para {
    width: 250px;
  }
  .para-text {
    font-size: 20px;
  }
  .cards-container-row-1 {
    justify-content: center;
    flex-wrap: wrap;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .team-cards {
    padding: 56px 0px 33px 0px;
    gap: 48px;
  }
  .team-heading-container {
    gap: 10px;
  }
  .heading-text {
    font-size: 15px;
  }
  .team-subheading {
    font-size: 45px;
    width: 300px;
  }
  .team-para {
    width: 250px;
  }
  .para-text {
    font-size: 20px;
  }
  .cards-container-row-1 {
    justify-content: center;
    flex-wrap: wrap;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .team-heading-container {
    gap: 10px;
  }
  .heading-text {
    font-size: 15px;
  }
  .team-subheading {
    font-size: 45px;
  }
  .team-para {
    width: auto;
  }
  .para-text {
    font-size: 20px;
  }
  .cards-container-row-1 {
    justify-content: center;
    flex-wrap: wrap;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .cards-container-row-1 {
    justify-content: center;
    flex-wrap: wrap;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .team-cards {
    padding: 46px 0px 23px 0px;
    gap: 48px;
  }
}

@media (min-width: 1201px) {
}
