.p_Banner {
  width: auto;
  height: 296.028px;
}
.background {
  position: relative;
  overflow: hidden;
  width: auto;
  height: 296.028px;
  background: rgba(160, 255, 223, 0.52);
  justify-content: center;
  align-items: center;
}
.background-circle {
  border-radius: 37.631px;
  background: #eef2fd;
}
.pattern-circle {
  position: absolute;
  top: -600px;
  left: 550px;
  border-radius: 958.328px;
  background: rgba(118, 255, 209, 0.52);
  width: 958.328px;
  height: 958.328px;
}
.pattern {
  position: absolute;
  margin-left: 5rem;
  margin-top: -1.5rem;
  transform: rotate(-0.2.897deg);
}
.banner-text-container {
  display: flex;
  justify-content: center;
  margin-top: 7rem;
}
.text-container-banner {
  display: flex;
  width: 594px;
  height: 80px;
  justify-content: center;
  align-items: center;
  gap: 5.83px;
  border-radius: 17.489px;
  border: 1.166px solid #fff;
  background: var(--success-700, #00ac73);
  box-shadow: 0px 30.314437866210938px 32.64632034301758px 0px
    rgba(187, 231, 255, 0.25);
  backdrop-filter: blur(24.776222229003906px);
}
.banner-text {
  color: var(--neutral-100, #edfdf8);
  text-align: center;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 47.454px */
}
.banner-right-container {
  width: 273.23px;
  height: 208.84px;
  flex-shrink: 0;
}
.banner-right-container {
}
@media only screen and (max-width: 768px) {
  
   
   .banner-text{
    font-size: x-large;
   }
    
}