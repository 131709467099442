.stories {
  display: flex;
  width: auto;
  padding: 120px 55px;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  background: #fff;
}
.stories-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}
.stories-card-container {
  display: flex;
  width: 1170px;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
}
.stories-card {
  display: flex;
  padding: 40px 30px;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  flex: 1 0 0;
  border-radius: 20px;
  border: 1px solid var(--cta-aqua-squeeze, #e5f4f2);
  background: #fff;
  box-shadow: 34.854148864746094px 29.626028060913086px 48.34000015258789px 0px
    rgba(0, 255, 170, 0.08);
}
.story-card-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  align-self: stretch;
}
.client-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.client-image {
  width: 56px;
  height: 56px;
  border-radius: 56px;
  object-fit: cover;
}
.client-details {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-self: stretch;
}
.client-name {
  color: var(--neutral-900, #111827);
  text-align: center;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.client-post {
  color: #374151;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
.client-review-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
.client-review {
  color: #2d2d2d;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.star-rating-section {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  align-self: stretch;
}
.star {
  color: #f8d57e;
}
.slider-controls {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.prev-button,
.next-button {
  cursor: pointer;
  padding: 10px 15px;
  font-size: 20px;
  background-color: #f8d57e;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.prev-button:hover,
.next-button:hover {
  background-color: #e4c966;
}
/* ... (existing styles) */

.stories-card-slider {
  margin: 0 auto;
  max-width: 1170px;
}

.slick-slider {
  position: relative;
}

.slick-prev,
.slick-next {
  /* ... (existing button styles) */
}

.slick-dots {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.slick-dots li button:before {
  width: 8px;
  height: 8px;
  background-color: #ccc;
  border-radius: 50%;
  content: "";
}

.slick-dots li.slick-active button:before {
  background-color: #333;
}
