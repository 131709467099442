.nearby-top {
  padding: 50px 0px 75px 0px;
  display: flex;
  justify-content: center;
}

.nearby-top-container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  gap: 6rem;
}
.nearby-top-image {
  width: 400px;
  height: 250px;
}
.nearby-heading {
  font-size: 42px;
  font-weight: bold;
}
.nearby-subheading {
  font-size: 18px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  width: 550px;
  line-height: 1.45;
}

.nearby-images {
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin-bottom: 3rem;
}
.nearby-card-left {
  display: flex;
  gap: 1.5rem;
}
.n-card-r-1 {
  display: flex;
  justify-content: center;
  width: 250px;
  height: 450px;
  padding-bottom: 0px;
  flex-direction: column;
  gap: 16px;
  border: 1px solid #eaecf0;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.n-card-faq-page {
  width: 310px;
  height: 230px;
  background: lightgray 50% / cover no-repeat;
}
.n-card-container-r-1 {
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding: 5px;
}
.n-card-container-text {
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.n-card-container-text-heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.n-card-heading {
  align-self: stretch;
  color: #282828;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 166.667% */
}
.n-card-subheading {
  align-self: stretch;
  color: #3e3e59;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.n-card-para {
  color: #5f6980;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.n-card-social-icons {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  margin-left: 10px;
}
.n-card-icons {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}
.n-card-icon-1 {
  display: flex;
  width: auto;
  height: 36px;
  padding: 9px;
  gap: 5px;
  justify-content: center;
  /* align-items: center; */
  border-radius: 100px;
  background: #f2f4f7;
}
.nearby-Right {
  margin-left: 80px;
}
.right-images {
  display: flex;
  gap: 25px;
}
.n-image-1 {
  height: 200px;
  width: 200px;
}
.n-image-2 {
  height: 300px;
  width: 200px;
  /* box-shadow: #7c5bff 15px 15px; */
}
/* Add this to your existing CSS */
.scrollable-container {
  overflow-x: auto;
  white-space: nowrap;
}

.n-mobile-scroll {
  margin-left: 10px;
  display: flex;
  gap: 10px;
}
.nearby-card-left-mobile {
  display: flex;
  gap: 10px;
}
.n-image-1-mobile {
  max-height: 250px;
  max-width: 250px;
}
.n-image-2-mobile {
  max-height: 300px;
  max-width: 200px;
}
.nearby-images-mobile,
.right-images-mobile {
  display: flex;
  gap: 10px;
}
.nearby-top-image-mobile {
  max-width: 400px;
  max-height: 250px;
}
@media (max-width: 353px) {
  .nearby-top {
    display: flex;
    justify-content: center;
  }
  .nearby-heading {
    font-size: 36px;
    /* width: 339px; */
  }
  .nearby-subheading {
    width: auto;
  }
  .nearby-top-container {
    margin-left: 10px;
    gap: 0px;
  }
  .nearby-top-image {
    display: none;
  }
  .nearby-images {
    display: none;
  }
}
@media (min-width: 354px) and (max-width: 1024px) {
  .nearby-top {
    display: flex;
    justify-content: center;
  }
  .nearby-heading {
    font-size: 42px;
    width: auto;
  }
  .nearby-subheading {
    width: auto;
  }
  .nearby-top-container {
    margin-left: 10px;
    gap: 0px;
  }
  .nearby-top-image {
    display: none;
  }
  .nearby-images {
    display: none;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .n-mobile-scroll {
    display: none;
  }
}
@media (min-width: 1200px) {
  .n-mobile-scroll {
    display: none;
  }
}
