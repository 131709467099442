.highlights {
  background-color: #ffffff;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.highlight-grid-container {
  display: flex;
  justify-content: space-between; /* Align items in a single line */
  gap: 10px;
  padding: 5px;
  margin-top: 30px;
}

.highlight-grid-Left,
.highlight-grid-Middle,
.highlight-grid-Right {
  width: 200px;
  height: 180px;
  display: flex;
  background: linear-gradient(180deg, #0F1B33 25%, #2E574A 100%);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* border: 2px solid black; */
  border-radius: 10px; /* Add border radius for rounded corners */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Add box shadow for depth */
}
.highlight-grid-Left:hover{
  transform: scale(1.05);
  cursor: pointer;
  filter: saturate(300%);
  box-shadow: 0 .2rem .2rem #2E574A;
}
.highlight-grid-Right:hover{
  transform: scale(1.05);
  cursor: pointer;
  filter: saturate(300%);
  box-shadow: 0 .2rem .2rem #2E574A;
}
.highlight-grid-Middle:hover{
  transform: scale(1.05);
  cursor: pointer;
  filter: saturate(300%);
  box-shadow: 0 .2rem .2rem #2E574A;
}

.highlight-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: transform 0.3s ease, background-color 0.3s ease;

}


.highlight-grid-name {
  margin-top: 16px;
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.highlight-grid-text {
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  flex-grow: 1;
}

/* Add hover effect */
.highlight-grid-Right:hover {
  transform: translateY(-5px); /* Move the box up slightly on hover */
  transition: transform 0.3s ease; /* Add smooth transition */
}


@media (max-width: 370px) {
  .highlight-grid-Left,
  .highlight-grid-Middle,
  .highlight-grid-Right {
    width: 100%; /* Full width for small screens */
  }
  .highlight-grid-name {
    font-size: 24px;
  }
  .highlight-grid-text {
    color: #fff;
    text-align: center;
    font-size: 18px;
  }
}

@media (min-width: 371px) and (max-width: 998px) {
  .highlight-grid-Left,
  .highlight-grid-Middle,
  .highlight-grid-Right {
    width: calc(50% - 10px); /* Adjust width for medium screens */
  }
}

/* Highlight.css */

/* Add media query for smaller screens */
@media (max-width: 998px) {
  .highlight-grid-container {
    flex-wrap: wrap; /* Allow flex items to wrap to the next line */
    justify-content: center; /* Center align items */
  }
  .highlight-grid-Left{
    width: 100%; /* Make width 100% for smaller screens */
    margin-top: 20px; /* Add margin to separate items */
  }
  .highlight-grid-Middle {
    width: calc(50% - 10px); /* Adjust width for two items in a row */
    margin-bottom: 20px; /* Add margin between rows */
  }
  .highlight-grid-Right {
    width: 100%; /* Make width 100% for smaller screens */
    margin-top: 20px; /* Add margin to separate items */
  }
}

