/* .container {
  display: flex;
  flex-direction: column;
}

.features {
  padding: 50px;
  margin-top: 45px;
  width: 1109px;
}

.feature-heading {
  font-size: 40px;
  color: #b8b8b8;
}
.headingf {
  color: #333;
  font-size: 42px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.App {
  position: relative;
  max-width: 1100px;
  margin: 0 auto;
}

.slick-slide > div {
  margin: 0 10px;
}

.slick-list {
  margin: 0 -10px;
}

.card {
  border: 1px solid #fff;
  background: #f1f1f1;
  border-radius: 8px;
  overflow: hidden;
  height: 350px;
  color: #1b1f27;
  cursor: pointer;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.card-top h1 {
  font-size: 0.9rem;
  margin: 20px;
  color: var(--neutral-900, #1b1f27);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.card-top > img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  background: black;
}

.card-bottom {
  margin: 10px 20px;
  color: var(--neutral-900, #1b1f27);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.category {
  position: relative;
}

.category::before {
  content: "";
  background: rgb(255, 61, 61);
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  right: 0;
  height: 2px;
}
.slick-slider {
  position: relative;
}

.slick-slide {
  padding: 5px;
}

.slider-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  border-radius: 50%;
  cursor: pointer;
  color: black;
  z-index: 1;
}

.slider-btn.next-btn {
  right: -50px;
}

.slider-btn.prev-btn {
  left: -50px;
}
@media (max-width: 320px) {
  .features {
    padding: 0;
  }

  .Feature-heading {
    padding: 0;
    font-size: 35px;
  }
  .headingf {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .container {
    flex-direction: row;
    justify-content: center;
  }

  .features,
  .App {
    flex: 1;
  }
  .slider-btn.next-btn {
    display: none;
  }

  .slider-btn.prev-btn {
    display: none;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .features {
    padding: 0;
  }

  .Feature-heading {
    padding: 0;
    font-size: 35px;
  }
  .headingf {
    font-size: 30px;
    margin-bottom: 20px;
    width: 550px;
  }
  .container {
    flex-direction: row;
    justify-content: center;
  }

  .features,
  .App {
    flex: 1;
  }
  .slider-btn.next-btn {
    display: none;
  }

  .slider-btn.prev-btn {
    display: none;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .features {
    padding: 0;
  }

  .Feature-heading {
    padding: 0;
    font-size: 35px;
  }
  .headingf {
    font-size: 30px;
    margin-bottom: 20px;
    width: 750px;
  }
  .container {
    flex-direction: row;
    justify-content: center;
  }

  .features,
  .App {
    flex: 1;
  }
  .slider-btn.next-btn {
    display: none;
  }

  .slider-btn.prev-btn {
    display: none;
  }
}
@media (min-width: 769px) and (max-width: 1023px) {
  .features {
    padding: 0;
  }

  .Feature-heading {
    padding: 0;
    font-size: 35px;
  }
  .headingf {
    font-size: 30px;
    margin-bottom: 20px;
    width: 850px;
  }
  .container {
    flex-direction: row;
    justify-content: center;
  }

  .features,
  .App {
    flex: 1;
  }
  .slider-btn.next-btn {
    display: none;
  }

  .slider-btn.prev-btn {
    display: none;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .slider-btn.next-btn {
    display: none;
  }

  .slider-btn.prev-btn {
    display: none;
  }
}
@media (min-width: 1201px) {
} */

/* .feature-container {
  margin-bottom: 8rem;
  margin-top: 8rem;
}
.background-color-green {
  background-color: #cae85d;
  content: " ";
  display: block;
  height: 15.5rem;
  position: relative;
  width: 100%;
}
.f-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.f-figure {
  position: absolute;
  top: -30%;
  left: 60%;
  right: 10%;
}
.f-image {
  height: 400px;
  width: 400px;
}
.f-heading {
  color: #201649;
  font-family: byrd, sans-serif;
  font-size: 40px;
  font-weight: 900;
  line-height: 1;
  margin-right: 35%;
  margin-top: 2%;
} */
.feature-section {
  padding: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.feature-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}
.f-left-container {
  margin-top: 50px;
}
.f-heading {
  font-size: 32px;
  font-weight: 700;
  margin-top: -10%;
}
.f-subheading {
  width: 500px;
  font-size: 18px;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.f-span {
  width: 500px;
  margin-bottom: 20px;
  font-size: 18px;
  margin-top: -10px;
}
.f-button {
  /* margin-top: 40px; */
  color: #fff;
  font-size: 16px;
  line-height: 100%;
  border-radius: 25px;
  letter-spacing: 0.5px;
  background: linear-gradient(148deg, #066042 0%, #00d890 100%);
}
.f-image {
  max-height: 350px;
  max-width: 400px;
  margin-right: 2rem;
  /* margin-top: -14%; */
}
.Ps7O6GY3PT5hQ5evKbe5 {
  display: flex;
  gap: 40px;
  padding: 20px;
}
.lhe7D12ocC8dqa7nVWSl {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.j40Lpa_11jxj26o6XX_S {
  align-content: center;
  align-items: center;
  display: flex;
  flex-flow: row;
  gap: 15px;
}

.pTxwDVMI6rC_geo2fLo8.EXJYCSOU5l6X2xNCgrfH {
}
.j40Lpa_11jxj26o6XX_S {
  align-content: center;
  align-items: center;
  display: flex;
  flex-flow: row;
  gap: 15px;
}
.pTxwDVMI6rC_geo2fLo8 {
  color: #201649;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.15;
}
.eQtQSMDJkR4BT1TaqVTY {
  height: 40px;
  width: 40px;
}
.WbYyjehfGCadjRd7twsc {
  margin-top: 6px;
}
.NYYRT23FN7njtIQZApjR {
  color: #201649;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.45;
}
.lhe7D12ocC8dqa7nVWSl {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
/* .background-color-green {
  background-color: #cae85d;
  content: " ";
  display: block;
  height: 15.5rem;
  position: relative;
  width: 100%;
} */
@media (max-width: 320px) {
  .feature-section {
    padding: 1rem;
  }
  .f-image {
    height: 350px;
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0rem;
  }
  .feature-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .f-heading {
    margin-top: 30px;
    font-size: 28px;
    width: auto;
  }
  .f-subheading {
    font-size: 20px;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    width: auto;
    margin-bottom: 20px;
  }
  .f-span {
    font-size: 20px;
    margin-top: 20px;
    width: auto;
  }
  .f-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
  .background-color-green {
    background-color: transparent;
  }
  .Ps7O6GY3PT5hQ5evKbe5 {
    display: flex;
    gap: 40px;
    padding: 20px;
    flex-direction: column;
  }
}
@media (min-width: 320px) and (max-width: 500px) {
  .feature-section {
    padding: 1rem;
  }
  .f-image {
    height: 350px;
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0rem;
  }
  .feature-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .f-heading {
    margin-top: 30px;
    font-size: 28px;
    width: auto;
  }
  .f-subheading {
    font-size: 20px;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    width: auto;
    margin-bottom: 20px;
  }
  .f-span {
    font-size: 20px;
    margin-top: 20px;
    width: auto;
  }
  .f-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
  .background-color-green {
    background-color: transparent;
  }
  .Ps7O6GY3PT5hQ5evKbe5 {
    display: flex;
    gap: 40px;
    padding: 20px;
    flex-direction: column;
  }
}
@media (min-width: 500px) and (max-width: 700px) {
  .feature-section {
    padding: 1rem;
  }
  .f-image {
    height: 350px;
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0rem;
  }
  .feature-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .f-heading {
    margin-top: 30px;
    font-size: 32px;
  }
  .f-subheading {
    font-size: 22px;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
  }
  .f-span {
    font-size: 22px;
    margin-top: 20px;
  }
  .f-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
  .background-color-green {
    background-color: transparent;
  }
  .Ps7O6GY3PT5hQ5evKbe5 {
    display: flex;
    gap: 40px;
    padding: 20px;
    flex-direction: column;
  }
}
@media (min-width: 700px) and (max-width: 738px) {
  .f-image {
    height: 300px;
    width: 300px;
    margin-right: 1.5rem;
  }
  .feature-section {
    padding: 1.8rem;
  }

  .f-heading {
    font-size: 20px;
  }
  .f-subheading {
    font-size: 18px;
    padding-top: 0.5rem;
    padding-bottom: 1.5rem;
  }
  .background-color-green {
    background-color: transparent;
  }
}
@media (min-width: 500px) and (max-width: 794px) {
  .f-image {
    height: 300px;
    width: 600px;
    margin-top: 30px;
  }
  .feature-section {
  }
  .feature-container {
    display: flex;
    flex-direction: column;
  }
  .f-heading {
    font-size: 32px;
    margin-top: 30px;
  }
  .f-subheading {
    width: 100%;
    font-size: 24px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .f-span {
    width: 100%;
    font-size: 18px;
    padding-bottom: 1.5rem;
  }
  .background-color-green {
    background-color: transparent;
  }
  .feature-section {
    padding: 1rem;
  }
}
@media (min-width: 794px) and (max-width: 852px) {
  .f-image {
    height: 300px;
    width: 300px;
    margin-right: -1rem;
  }
  .feature-section {
    padding: 1.8rem;
  }

  .f-heading {
    font-size: 20px;
  }
  .f-subheading {
    font-size: 18px;
    padding-top: 0.5rem;
    padding-bottom: 1.5rem;
  }
  .background-color-green {
    background-color: transparent;
  }
}
@media (min-width: 853px) and (max-width: 968px) {
  .feature-section {
    padding: 1rem;
  }
  .f-image {
    height: 300px;
    width: 350px;
    margin-right: 2rem;
  }
  .feature-section {
    padding: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .f-heading {
    font-size: 22px;
  }
  .f-subheading {
    font-size: 18px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .background-color-green {
    background-color: transparent;
  }
}
@media (min-width: 969px) and (max-width: 1023px) {
  .feature-section {
    padding: 3rem;
  }
  .feature-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .f-heading {
    font-size: 25px;
  }
  .f-subheading {
    font-size: 16px;
  }
  .background-color-green {
    background-color: transparent;
  }
}
@media (min-width: 1024px) {
  .background-color-green {
    background-color: transparent;
  }
}
