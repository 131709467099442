.testimonial {
  margin-top: 50px;
  position: relative;
  display: flex;
  width: auto;
  padding: 58px 80px 80px 80px;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  height: 750px;
}
.testimonial-slide {
  height: 350px;
}
#app {
  height: 100%;
}

.swiper {
  display: flex;
  width: auto;
  height: 383.4px;
  padding: 25.199px 28.8px 25.201px 28.8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 10.8px;
  background: #f5f2ed;
}

.swiper-slide {
  /* text-align: center; */
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.active-slider-upper {
  display: flex;
  width: 279px;
  height: 176.4px;
  flex-direction: column;
  gap: 7.2px;
  margin-top: 40px;
  margin-left: 30px;
}
.active-slider-active-text {
  display: flex;
  width: 279px;
  height: 97.2px;
  padding: 0.9px 24.291px 0.9px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.8px;
}
.text-slider-active {
  display: flex;
  width: 254.709px;
  height: 30.6px;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  color: #413d45;
  font-family: Inter;
  font-size: 24.3px;
  font-style: normal;
  font-weight: 500;
  line-height: 32.4px; /* 133.333% */
  letter-spacing: -0.63px;
}
.active-slider-bottom {
  display: flex;
  margin-top: 70px;
  width: 279px;
  padding-right: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8.3px;
  margin-left: 30px;
}
.active-slider-active-name {
  color: #67646a;
  font-family: Inter;
  font-size: 11.7px;
  font-style: normal;
  font-weight: 500;
  line-height: 16.2px; /* 138.462% */
  letter-spacing: -0.126px;
}
.active-slider-joinDate {
  display: flex;
  width: 214.857px;
  height: 12.6px;
  color: #413d45;
  font-family: Inter;
  font-size: 9.9px;
  font-style: normal;
  font-weight: 500;
  line-height: 14.4px; /* 145.455% */
  letter-spacing: -0.108px;
}
.mySwiper .testimonial-slide {
  position: relative;
  z-index: 1;
}

.mySwiper .testimonial-slide:not(.active-slider) {
  opacity: 0.5; /* Adjust the opacity as per your preference */
  pointer-events: none; /* This prevents non-active slides from being clickable */
}

/* Add styles for the active slide */
.mySwiper .testimonial-slide.active-slider {
  z-index: 2;
}
.swiper-container {
  width: 100%;
  max-width: 1200px; /* Adjust as needed */
  margin: 0 auto; /* Center the container horizontally */
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.swiper-navigation {
  margin-top: 30px;
  position: absolute;
  right: 120px;
  bottom: 90px;
}

.testimonial-prev {
  display: flex;
  width: 28.8px;
  height: 28.8px;
  flex-shrink: 0;
}
.testimonial-next {
  width: 28.8px;
  height: 28.8px;
  flex-shrink: 0;
}
.test-quote {
  width: 67.5px;
  height: 46.587px;
  fill: #ff7e1d;
}
.active-slider-active-icon {
  margin-left: -15px;
  margin-bottom: 10px;
}
/* For screens with a maximum width of 320px */
@media (max-width: 320px) {
  .testimonial {
    padding: 20px; /* Adjust padding as needed */
    height: auto; /* Allow height to adjust based on content */
  }

  .swiper-container {
    max-width: 100%; /* Make the swiper container occupy the full width */
  }

  .swiper-slide {
    width: 100%; /* Make each slide occupy the full width */
  }

  .active-slider-upper,
  .active-slider-bottom {
    width: 100%; /* Make the content occupy the full width */
    padding: 10px; /* Adjust the padding as needed */
  }

  .text-slider-active {
    font-size: 20px; /* Adjust the font size for better mobile display */
  }

  .active-slider-active-name {
    font-size: 10px; /* Adjust the font size for better mobile display */
  }

  .active-slider-joinDate {
    font-size: 8px; /* Adjust the font size for better mobile display */
  }

  .swiper-navigation {
    right: 10px; /* Adjust the navigation position */
    bottom: 20px; /* Adjust the navigation position */
  }

  .swiper-button-prev,
  .swiper-button-next {
    font-size: 20px; /* Adjust the font size of navigation buttons */
  }

  .testimonial-prev,
  .testimonial-next {
    display: none; /* Hide the custom navigation buttons on mobile */
  }
}

/* For screens with a width between 321px and 480px */
@media (min-width: 321px) and (max-width: 480px) {
  .testimonial {
    padding: 30px; /* Adjust padding as needed */
    gap: 30px; /* Adjust the gap between slides */
  }

  .swiper-slide {
    width: 90%; /* Make each slide occupy 90% of the container width */
  }

  .active-slider-upper,
  .active-slider-bottom {
    padding: 20px; /* Adjust the padding as needed */
    margin-left: 30px; /* Adjust the margin
  }

  .text-slider-active {
    font-size: 22px; /* Adjust the font size for better mobile display */
  }

  .active-slider-active-name {
    font-size: 12px; /* Adjust the font size for better mobile display */
  }

  .active-slider-joinDate {
    font-size: 10px; /* Adjust the font size for better mobile display */
  }

  .swiper-navigation {
    right: 30px; /* Adjust the navigation position */
    bottom: 40px; /* Adjust the navigation position */
  }

  .swiper-button-prev,
  .swiper-button-next {
    font-size: 24px; /* Adjust the font size of navigation buttons */
  }
}

/* For screens with a width between 481px and 768px */
@media (min-width: 481px) and (max-width: 768px) {
  .testimonial {
    padding: 40px;
    gap: 40px;
  }

  .swiper-slide {
    width: 70%;
  }

  .active-slider-upper,
  .active-slider-bottom {
    margin-top: 20px;
    padding: 30px;
    margin-left: 20px;
  }

  .text-slider-active {
    font-size: 24px;
  }

  .active-slider-active-name {
    font-size: 14px;
  }

  .active-slider-joinDate {
    font-size: 12px;
  }

  .swiper-navigation {
    right: 40px;
    bottom: 50px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    font-size: 28px;
  }
  .test-quote {
    width: 67.5px;
    height: 46.587px;
    fill: #ff7e1d;
    margin-bottom: 30px;
  }
}

/* For screens with a width between 769px and 1023px */
@media (min-width: 769px) and (max-width: 1023px) {
  .testimonial {
    padding: 40px;
    gap: 40px;
  }

  .swiper-slide {
    width: 70%;
  }

  .active-slider-upper,
  .active-slider-bottom {
    margin-top: 20px;
    padding: 30px;
    margin-left: 20px;
  }

  .text-slider-active {
    font-size: 24px;
  }

  .active-slider-active-name {
    font-size: 14px;
  }

  .active-slider-joinDate {
    font-size: 12px;
  }

  .swiper-navigation {
    right: 40px;
    bottom: 50px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    font-size: 28px;
  }
  .test-quote {
    width: 67.5px;
    height: 46.587px;
    fill: #ff7e1d;
    margin-bottom: 30px;
  }
}

/* For screens with a width between 1024px and 1200px */
@media (min-width: 1024px) and (max-width: 1200px) {
  /* Keep the existing styles for this breakpoint */
}

/* For screens with a width greater than 1200px, no additional styles needed */
@media (min-width: 1201px) {
  /* Keep the existing styles for larger screens */
}
