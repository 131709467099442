.footer {
  display: flex;
  width: auto;
  padding: 48px 80px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 48px;
  background: #1b1f27;
}
.footer-top {
  display: flex;
  align-items: center;
  gap: 48px;
  align-self: stretch;
}
.footer-top-image-container {
  display: flex;
  padding-right: 0px;
  align-items: center;
}
.footer-top-image {
  width: 133px;
  height: 47px;
}
.footer-top-right-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 16px;
  flex: 1 0 0;
}
.footer-top-right-fields {
  display: flex;
  align-items: center;
  gap: 16px;
}

.right-input-filed {
  display: flex;
  width: 250px;
  height: 48px;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid var(--negative-100, #fef1f2);
  background: var(--neutral-200, #f1f4ec);
}
.footer-right-button {
  display: flex;
  height: 48px;
  padding: 16px 12px;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--success-700, #00ac73);
  background: var(--success-700, #00ac73);
}
.footer-button {
  color: var(--neutral-0, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.5px;
}
.line {
  height: 2px;
  align-self: stretch;
  background: #fef1f2;
}
.footer-columns {
  display: flex;
  align-items: flex-start;
  gap: 48px;
  align-self: stretch;
}
.footer-column-1,
.footer-column-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
}
.footer-col-1-heading,
.footer-col-2-heading,
.footer-col-3-heading {
  display: flex;
  padding: 12px 0px;
  align-items: center;
  gap: 8px;
}
.footer-col-heading {
  color: var(--neutral-0, #fff);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
}

.footer-col-1-menus,
.footer-col-2-para {
  display: flex;
  align-items: center;
  gap: 8px;
}
.footer-col-menu {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}
.footer-col-menu a {
  text-decoration: none;
  color: #fff;
}
.footer-column-3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
}
.col-3-top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.button-groups {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  cursor: pointer;
}
.logo-playStore,
.logo-apple {
  width: 135px;
  height: 45px;
}

.social-buttons {
  display: flex;
  width: 427px;
  align-items: center;
  gap: 16px;
  cursor: pointer;
}
.footer-bottom {
  display: flex;
  align-items: flex-start;
  gap: 48px;
  align-self: stretch;
}
.bottom-text-left {
  color: var(--neutral-0, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.bottom-menus {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
}
.bottom-menu-1,
.bottom-menu-2,
.bottom-menu-3 {
  display: flex;
  padding: 12px 8px;
  align-items: center;
  gap: 8px;
}
.footer-bottom-menu {
  color: var(--neutral-0, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}
@media (max-width: 320px) {
  .footer-column-2 {
    display: none;
  }
  .footer {
    padding: 18px 30px;
  }
  .footer-top {
    display: flex;
    flex-direction: column;
  }
  .footer-columns {
    display: flex;
    flex-direction: column;
  }
  .footer-column-2 {
    display: none;
  }
  .bottom-menus {
    display: none;
  }
  .footer-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .right-input-filed {
    width: 220px;
  }
  .footer-top-right-fields {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .button-groups {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .footer-column-2 {
    display: none;
  }
  .footer {
    padding: 18px 30px;
  }
  .footer-top {
    display: flex;
    flex-direction: column;
  }
  .footer-columns {
    display: flex;
    flex-direction: column;
  }
  .footer-column-2 {
    display: none;
  }
  .bottom-menus {
    display: none;
  }
  .footer-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .right-input-filed {
    width: 210px;
  }
  .footer-top-right-fields {
    gap: 8px;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .footer-top {
    display: flex;
    flex-direction: column;
  }
  .footer-column-3 {
    margin-right: -120px;
  }
  .footer-column-2 {
    display: none;
  }
  .footer-column-1 {
    margin-left: 30px;
  }
  .footer {
    padding: 28px 0px;
  }
  .bottom-menus {
    display: none;
  }
  .footer-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 769px) and (max-width: 1023px) {
  .footer-column-2 {
    display: none;
  }
  .footer-column-3 {
    margin-right: -120px;
  }
  .footer {
    padding: 28px 60px;
  }
  .footer-columns {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .footer {
    width: 100%;
    padding: 28px 60px;
  }
}
@media (min-width: 1201px) {
}
