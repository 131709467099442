.market-place-main {
  padding: 0px 80px 75px 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.mp-top-container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  gap: 6rem;
}
.last-image {
  margin-top: -150%;
  width: 180px;
  height: 250px;
  box-shadow: rgba(240, 46, 170, 0.4) 15px -20px;
}
.mp-top-image {
  max-width: 300px;
  max-height: 500px;
}
.mp-top-image-2 {
  width: 180px;
  height: 180px;
}
.market-place {
  margin-top: -75px;
  display: flex;
  gap: 1rem;
}
.market-place-card {
  display: flex;
  justify-content: center;
  width: 200px;
  height: 420px;
  padding-bottom: 0px;
  flex-direction: column;
  gap: 16px;
  border: 1px solid #eaecf0;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.mp-card {
  width: 280px;
  height: 200px;
  background: lightgray 50% / cover no-repeat;
}
.mp-card-container {
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding: 5px;
}
.mp-card-container-text {
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.mp-card-subheading {
  align-self: stretch;
  color: #3e3e59;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.mp-card-para {
  color: #5f6980;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  margin-top: 10px;
}
.mp-card-social-icons {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-left: 10px;
}
.mp-card-icons {
  display: flex;
  gap: 16px;
}
.mp-card-icon-1 {
  display: flex;
  width: 50px;
  height: 25px;
  padding: 9px;
  gap: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #e9e8ed;
}
.market-place-mobile {
  display: flex;
  gap: 10px;
}
.last-image-mobile {
  margin-top: 20px;
  max-width: 300px;
  max-height: 300px;
  box-shadow: rgba(240, 46, 170, 0.4) 15px -40px;
}
.mp-top-image-mobile {
  max-width: 300px;
  max-height: 500px;
}
.mp-top-image-2-mobile {
  width: 180px;
  height: 180px;
}
@media (max-width: 320px) {
  .mp-top-image,
  .mp-top-image-2,
  .last-img {
    display: none;
  }
  .market-place-main {
    padding: 50px 0px 75px 10px;
    width: 380px;
  }
  .market-place {
    display: none;
  }
}
@media (min-width: 320px) and (max-width: 353px) {
  .mp-top-image,
  .mp-top-image-2,
  .last-img {
    display: none;
  }
  .market-place-main {
    padding: 50px 0px 75px 10px;
    width: 420px;
  }
  .market-place {
    display: none;
  }
}
@media (min-width: 354px) and (max-width: 1024px) {
  .mp-top-image,
  .mp-top-image-2,
  .last-img {
    display: none;
  }
  .market-place-main {
    padding: 50px 0px 75px 10px;
  }
  .market-place {
    display: none;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .market-place-main {
    padding: 50px 0px 75px 10px;
  }
  .mp-top-container {
    gap: 1rem;
  }
  .last-image {
    margin-top: -90px;
  }
}
@media (min-width: 1200px) {
  .market-place-main {
    padding: 50px 0px 75px 10px;
  }

  .last-image {
    margin-top: -120px;
  }
}
