.faqs {
  display: flex;
  width: auto;
  justify-content: center;
  align-items: center;
  padding: 50px 0px 78px 0px;
  gap: 67px;
}
.heading{
  font-size: xx-large;
  font-weight: bolder;
  margin-top: 10%;
  align-items: center;
  justify-content: center;
  margin-left: 10%;
}
.faqs-top-container {
  display: flex;
  align-items: center;
}
.faqs-top-heading-container {
  width: 735px;
}
.faqs-heading {
  color: var(--neutral-900, #1b1f27);
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
}
.faqs-search-container {
  display: flex;
  width: 442px;
  flex-direction: column;
  align-items: center;
  gap: 9.002px;
  box-shadow: 0px 0.900209367275238px 1.800418734550476px 0px rgba(0, 0, 0, 0.2),
    0px 0.09002093970775604px 0.2700628340244293px 0px rgba(0, 0, 0, 0.1);
}
.faqs-search-input {
  display: flex;
  height: 43.21px;
  padding: 10.803px 14.403px;
  align-items: center;
  gap: 21.605px;
  align-self: stretch;
  border-radius: 1.8px;
  background: var(--system-backgrounds-sbl-primary, #fff);
}
.faqs-search-icon {
  display: flex;
  width: 25.206px;
  height: 25.206px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--label-colors-lcl-secondary, rgba(60, 60, 67, 0.6));
  text-align: center;
  font-size: 16.204px;
  font-style: normal;
  font-weight: 500;
  line-height: 45.01px;
}
.search-icon {
  width: 19.644px;
  height: 19.644px;
  flex-shrink: 0;
}
.faqs-search-input-main {
  flex: 1 0 0;
  color: var(--label-colors-lcl-secondary, rgba(60, 60, 67, 0.6));
  font-feature-settings: "clig" off, "liga" off;
  font-size: 15.304px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.805px;
  letter-spacing: 0.45px;
}
.faqs-search {
  width: 100%;
  height: auto;
  outline: none;
}
.faqs-close-icon {
  width: 21.605px;
  height: 21.605px;
}
.faqs-question {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}
.faqs-question-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.faqs-questions {
  display: flex;
  width: auto;
  padding: 64px 48px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background: #f9fbf7;
}
.faqs-question-content {
  display: flex;
  width: auto;
  align-items: flex-start;
}
.faqs-question-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 64px;
  flex: 1 0 0;
}
.upper-row,
.bottom-row {
  display: flex;
  align-items: flex-start;
  gap: 64px;
  align-self: stretch;
}
.first-question-container,
.second-question-container,
.third-question-container,
.forth-question-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
}
.first-question,
.second-question,
.third-question,
.forth-question {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.first-question-heading,
.second-question-heading,
.third-question-heading,
.forth-question-heading {
  flex: 1 0 0;
  color: var(--text-txt-primary, #000);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
.first-question-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.fq-text {
  align-self: stretch;
  color: var(--text-txt-secondary, rgba(60, 60, 67, 0.85));
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.faq-accordionData {
  display: flex;
  width: auto;
  padding: 58px 80px 80px 80px;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 320px) {
  .faqs-top-container {
    flex-direction: column;
  }
  .faqs-questions {
    padding: 14px 8px;
    flex-direction: column;
    align-items: center;
    width: fit-content;
  }
  .upper-row,
  .bottom-row {
    display: flex;
    flex-direction: column;
  }
  .faqs-heading {
    font-size: 20px;
  }
  .faqs-top-heading-container {
    width: auto;
    margin-bottom: 25px;
  }
  .faqs-search-container {
    width: 242px;
  }
  .faq-accordionData {
    padding: 58px 0px 80px 0px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .faqs-top-container {
    flex-direction: column;
  }
  .faqs-questions {
    padding: 34px 18px;
    flex-direction: column;
    align-items: center;
  }
  .upper-row,
  .bottom-row {
    display: flex;
    flex-direction: column;
  }
  .faqs-heading {
    font-size: 20px;
  }
  .faqs-top-heading-container {
    width: auto;
    margin-bottom: 25px;
  }
  .faqs-search-container {
    width: 242px;
  }
  .faq-accordionData {
    padding: 38px 0px 0px 0px;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .faqs-top-container {
    flex-direction: column;
  }
  .faqs-questions {
    padding: 44px 28px;
    flex-direction: column;
    align-items: center;
  }
  .upper-row,
  .bottom-row {
    display: flex;
    flex-direction: column;
  }
  .faqs-heading {
    font-size: 30px;
  }
  .faqs-top-heading-container {
    width: auto;
    margin-bottom: 25px;
  }
  .faqs-search-container {
    width: 242px;
  }
  .faq-accordionData {
    padding: 58px 0px 80px 0px;
  }
}
@media (min-width: 769px) and (max-width: 1023px) {
  .faqs-heading {
    font-size: 30px;
  }
  .faqs-top-heading-container {
    width: 435px;
  }
  .faqs-search-container {
    width: 342px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .faqs-top-heading-container {
    width: 535px;
  }
}
