.pricing {
  height: 915px;
}
.pricing-header {
  display: flex;
  justify-content: center;
  margin-top: 80px;
  height: 86px;
}
.pricing-text {
  color: #034f75;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.p-options {
  display: flex;
  justify-content: center;
}
.p-rectangle-container {
  width: 452px;
  height: 56px;
  border-radius: 3px;
  border: 1px solid #034f75;
  background: #d3e7f0;
  display: flex;
}
.rect-1 {
  width: 224px;
  height: 55px;
  border-radius: 3px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.r-1-text {
  color: #034f75;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.rect-2 {
  width: 224px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.r-2-text {
  color: #81a7ba;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.pc-sub-heading {
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pricing-subtext {
  color: #81a7ba;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.pc-sub-span {
  color: #034f75;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.pricing-card-group {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin-top: 30px;
}
.pc-card-1 {
  width: 362px;
  height: 450px;
  border-radius: 10px;
  background: #fffefe;
  margin-top: 40px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.pc-1-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.pc-1-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 128px;
  border-radius: 10px;
}
.pc-1-h-text {
  color: #034f75;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.pc-1-number {
  color: #034f75;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Price_GST {
  display: flex;
}
.GST {
  font-weight: 600;
  font-size: 18px;
}
.GST-2 {
  font-weight: 600;
  font-size: 18px;
  color: #fff;
}
.number-text {
  color: #81a7ba;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}
.option-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pc-1-options {
  height: 233.012px;
  margin-top: 20px;
}
.pc-options {
  display: flex;
  align-items: center;
  gap: 10px;
}
.pc-text-green {
  color: #034f75;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.pc-text-red {
  color: #81a7ba;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.purchase-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.purchase-button {
  width: 250px;
  border-radius: 10px;
  background: #d3e7f0;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.purchase-text {
  color: #034f75;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.pc-card-2 {
  width: 362px;
  height: 450px;
  border-radius: 10px;
  background: #fffefe;
  margin-top: 40px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.pc-2-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.pc-2-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 128px;
  border-radius: 10px;
}
.pc-2-h-text {
  color: #fffefe;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 20%;
}
.pc-2-number {
  color: #fff;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.number-text-2 {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}
.pc-2-options {
  height: 233.012px;
  margin-top: 30px;
}
.pc-options-2 {
  display: flex;
  align-items: center;
  gap: 10px;
}
.pc-text-green-2 {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.purchase-button-container-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.purchase-button-2 {
  width: 250px;
  border-radius: 10px;
  background: #fff;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.detail-plan-container {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.detail-plan {
  margin: 30px 70px 70px 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 5px;
}
.detail-plan-text {
  color: #034f75;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.getstarted-btn {
  font-style: normal;
  background: #cae85d;
  border-radius: 4px;
  color: #000;
  font-weight: 700;
  padding: 12px 30px;
  border: 2px solid black;
}

/* 320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV */
.darr{
  display: flex;
}
@media (max-width: 320px) {
  .pricing-header {
    margin-top: 20px;
    height: 60px;
  }
  .pricing-text {
    font-size: 28px;
  }
  .pricing-subtext {
    font-size: 18px;
  }
  .pc-sub-span {
    font-size: 18px;
  }
  .p-rectangle-container {
    width: 200px;
    height: 56px;
  }
  .pricing-card-group {
    flex-direction: column;
    gap: 50px;
    margin-top: 30px;
  }
  .pc-card-1 {
    width: 320px;
    height: 450px;
    margin-top: 0px;
  }
  .pc-card-2 {
    width: 320px;
    height: 480px;
  }
  .purchase-button {
    width: 180px;
  }
  .purchase-text {
    font-size: 18px;
  }
  .purchase-button-container-2 {
    margin-top: 0px;
  }
  .purchase-button-2 {
    width: 180px;
  }
  .detail-plan {
    margin: 10px 10px 10px 10px;
  }
  .darr{
    flex-direction: column;
  }
}
@media (min-width: 320px) and (max-width: 356px) {
  .pricing-header {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    height: 86px;
  }
  .pricing-text {
    font-size: 34px;
  }
  .pricing-subtext {
    font-size: 20px;
  }
  .pc-sub-span {
    font-size: 20px;
  }
  .p-rectangle-container {
    width: 250px;
    height: 56px;
  }
  .pricing-card-group {
    flex-direction: column;
    gap: 50px;
    margin-top: 30px;
  }
  .pc-card-1 {
    width: 320px;
    height: 450px;
    margin-top: 0px;
  }
  .pc-card-2 {
    width: 320px;
    height: 480px;
  }
  .detail-plan {
    margin: 10px 10px 10px 10px;
  }
  .darr{
    flex-direction: column;
  }
}
@media (min-width: 356px) and (max-width: 480px) {
  .pricing-header {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    height: 86px;
  }
  .pricing-text {
    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .pricing-subtext {
    font-size: 24px;
  }
  .pc-sub-span {
    font-size: 24px;
  }
  .p-rectangle-container {
    width: 300px;
  }
  .pricing-card-group {
    flex-direction: column;
    gap: 50px;
    margin-top: 30px;
  }
  .pc-card-1 {
    width: 320px;
    height: 450px;
    margin-top: 20%;
  }
  .pc-card-2 {
    width: 320px;
    height: 480px;
  }
  .detail-plan {
    margin: 20px 20px 20px 20px;
  }
  .darr{
    flex-direction: column;
  }
}
@media (min-width: 481px) and (max-width: 898px) {
  .pricing-card-group {
    flex-direction: column;
    gap: 50px;
    margin-top: 30px;
  }
  .pc-card-1 {
    width: 400px;
    height: 450px;
    margin-top: 20%;
  }
  .pc-card-2 {
    width: 400px;
    height: 480px;
  }
  .darr{
    flex-direction: column;
  }
}
@media (min-width: 898px) and (max-width: 1024px) {
  .pricing-card-group {
    gap: 15px;
    margin-top: 30px;
  }
  .pc-card-1 {
    width: 280px;
    height: 525px;
  }
  .pc-card-2 {
    width: 300px;
    height: 565px;
  }
  .darr{
    flex-direction: column;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .pricing-card-group {
    gap: 30px;
    margin-top: 30px;
  }
  .pc-card-1 {
    width: 300px;
    height: 525px;
  }
  .pc-card-2 {
    width: 320px;
    height: 565px;
  }
}
