.installApp {
  display: flex;
  width: auto;
  height: 646px;
  padding: 80px 80px 0px 80px;
  align-items: flex-start;
  gap: 80px;
  flex-shrink: 0;
}
.phone-Screen-container {
  display: flex;
  padding: 0px 80px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.ia-text-container {
  display: flex;
  padding: 80px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 64px;
  flex: 1 0 0;
}
.phone-border {
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 60px;
  border: 1px solid var(--cool-gray-30, #c1c7cd);
  background: var(--cool-gray-20, #dde1e6);
}
.phone-image {
  width: 320px;
  height: 600px;
  border-radius: 60px;
  border: 0px solid var(--cool-gray-30, #c1c7cd);
  background: url("../../Assets/Image.png"),
    lightgray 50% / cover no-repeat #f2f4f8;
}
.ia-text-container {
  display: flex;
  padding: 80px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 64px;
  flex: 1 0 0;
}
.ia-text-container-heading {
  color: var(--cool-gray-90, #21272a);
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 63px */
}
.ia-text-container-body {
  color: var(--cool-gray-90, #21272a);
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 42px */
}
.ia-text-container-buttons {
  display: flex;
  align-items: flex-start;
  gap: 18.503px;
}
.ia-apple-button,
.ia-playStore-icon {
  width: 138.381px;
  height: 46.257px;
}
/* Bottom Images Container */
.ia-download {
  display: flex;
  padding: 121px 383px 99px 383px;
  justify-content: center;
  align-items: center;
}
.ia-download-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 69px;
}
.ia-download-container-header {
  width: 550px;
  color: var(--cool-gray-90, #21272a);
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 42px */
}
.ia-download-container-images {
  display: flex;
  align-items: flex-start;
  gap: 110px;
  width: auto;
}
.ia-download-container-apple,
.ia-download-container-android {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 41px;
}
.apple-image,
.android-image {
  width: 220px;
  height: 220px;
}
.apple-title-container,
.android-title-container {
  display: flex;
  width: 160px;
  height: 60px;
  padding: 4px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 2.5px solid var(--neutral-700, #3f444d);
}
.apple-title,
.android-title {
  color: #000;
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 42px */
}
@media (max-width: 320px) {
  .phone-Screen-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0px;
    margin-bottom: 40px;
  }
  .ia-text-container-buttons {
    display: flex;
  }
  .installApp {
    display: flex;
    width: auto;
    height: 646px;
    padding: 40px 0px 40px 0px;
    align-items: center;
    gap: 0px;
    flex-direction: column;
    margin-bottom: 50px;
  }
  .phone-border {
    /* margin-left: -90px; */
  }
  .ia-download {
    display: flex;
    padding: 91px 0px 99px 0px;
    justify-content: center;
    align-items: center;
  }
  .ia-download-container {
    gap: 40px;
  }
  .ia-download-container-header {
    margin-top: 20px;
    max-width: 200px;
  }
  .ia-download-container-images {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  .ia-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
  .ia-text-container-heading {
    font-size: 30px;
    margin-top: -110px;
  }
  .ia-text-container-body {
    padding-left: 20px;
    font-size: 21px;
    display: flex;
  }
  .apple-image,
  .android-image {
    width: 170px;
    height: 170px;
  }
  .phone-image {
    width: 250px;
    height: 450px;
    border-radius: 60px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .phone-Screen-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0px;
    margin-bottom: 40px;
  }
  .ia-text-container-buttons {
    display: flex;
  }
  .installApp {
    display: flex;
    width: auto;
    height: 646px;
    padding: 40px 0px 40px 0px;
    align-items: center;
    gap: 0px;
    flex-direction: column;
    margin-bottom: 50px;
  }
  .phone-border {
    /* margin-left: -90px; */
    margin-top: 10%;

  }
  .ia-download {
    display: flex;
    padding: 91px 0px 99px 0px;
    justify-content: center;
    align-items: center;
  }
  .ia-download-container {
    gap: 40px;
  }
  .ia-download-container-header {
    margin-top: 15px;
    /* font-size: 25px; */
    max-width: 400px;
  }
  .ia-download-container-images {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  .ia-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
  .ia-text-container-heading {
    font-size: 30px;
    margin-top: -110px;
  }
  .ia-text-container-body {
    padding-left: 20px;
    font-size: 21px;
    display: flex;
  }
  .apple-image,
  .android-image {
    width: 170px;
    height: 170px;
  }
  .phone-image {
    width: 250px;
    height: 450px;
    border-radius: 60px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .phone-Screen-container {
    /* margin-left: -80px; */
  }

  .installApp {
    display: flex;
    width: auto;
    height: 646px;
    padding: 40px 0px 40px 0px;
    align-items: center;
    gap: 0px;
  }
  .phone-border {
    margin-left: -90px;
    margin-top: 40%;
  }
  .ia-download {
    display: flex;
    padding: 91px 0px 99px 0px;
    justify-content: center;
    align-items: center;
  }
  .ia-download-container {
    gap: 40px;
  }
  .ia-download-container-header {
    font-size: 25px;
    max-width: 400px;
  }
  .ia-download-container-images {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  .ia-text-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-left: -40px;
    width: 100px;
  }
  .ia-text-container-heading {
    font-size: 30px;
    margin-top: -110px;
  }
  .ia-text-container-body {
    font-size: 20px;
  }
  .apple-image,
  .android-image {
    width: 170px;
    height: 170px;
  }
  .phone-image {
    width: 250px;
    height: 400px;
    border-radius: 60px;
  }
}
@media (min-width: 769px) and (max-width: 1023px) {
  .installApp {
    display: flex;
    width: auto;
    height: 646px;
    padding: 40px 0px 0px 0px;
    align-items: flex-start;
    gap: 5px;
    flex-shrink: 0;
  }
  .phone-border {
    margin-left: -150px;
    margin-top: 10%;
  }
  .phone-image{
    display: flex;
    align-items: center;
    height: 10%;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .installApp {
    display: flex;
    width: auto;
    height: 646px;
    padding: 60px 0px 0px 0px;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
  }
}
@media (min-width: 1201px) {
}
