.get-start {
  padding-top: 30px;
  margin-top: 50px;
  background-color: #ffffff;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gs-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gs-title {
  color: #5b9092;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.gs-text {
  color: #1b1f27;
  text-align: center;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.gs-main {
  display: flex;
}

.gs-right-part {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 485px;
  align-items: center;
  gap: 64px;
}

.gs-right-content-top,
.gs-right-content-bottom {
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  color: var(--neutral-900, #1b1f27);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

/* Responsive Styles */
@media (max-width: 320px) {
  .gs-text {
    font-size: 20px;
  }

  .gs-main {
    flex-direction: column;
    align-items: center;
  }

  .gs-right-part {
    display: none;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .gs-text {
    font-size: 30px;
  }

  .gs-main {
    flex-direction: column;
    align-items: center;
  }

  
  .gs-video{
    display: flex;
  }

  .gs-right-part {
    display: none;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .gs-text {
    font-size: 30px;
  }

  .gs-main {
    flex-direction: column;
    align-items: center;
  }

  .gs-right-part {
    display: none;
  }
}
@media (min-width: 769px) and (max-width: 1023px) {
  .gs-text {
    font-size: 30px;
  }

  .gs-main {
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
  .gs-video{
    display: flex;
    width: fit-content;
  }

  .gs-right-part {
    display: none;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .gs-text {
    font-size: 30px;
  }

  .gs-main {
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }

  .gs-right-part {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
}
@media (min-width: 1201px) {
  .gs-text {
    font-size: 30px;
  }

  .gs-main {
    /* flex-direction:column; */
    align-items: center;
    gap: 32px;
  }

  .gs-right-part {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

