.about-us-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: hidden;
  overflow-x: hidden;
}
.a_upper-section {
  width: 100%;
  height: 600px;
  overflow: hidden;
}
.dipptext{
  color: green;
  font-size: xx-large;
  font-weight: bold;
  margin-left: 3%; 
}
.dippimage{
  display: flex;
  width: 50%;
  height: 50%;
}
.dippcontainer{
  align-items: center;
  display: flex;
  justify-content: center;
}
.decore {
  padding: 0px 0px 0px 300px;
}
.swiper-about-container {
  display: flex;
  width: 1039.408px;
  justify-content: center;
  align-items: center;
  gap: 18.478px;
}
.a_swiper {
  position: absolute;
  top: 120px;
  bottom: 50%;
  left: 200px;
  right: 50%;
  width: 70%;
  height: 560px;
  border: 1.155px solid #dde1e6;
  background: #fff;
}
.testimonial-box {
  display: flex;
  padding: 27.718px;
  flex-direction: column;
  align-items: center;
  gap: 18.478px;
  height: auto;
  block-size:fit-content;
}
.aboutus-image {
  display: flex;
  width: 100.827px;
  height: 100.827px;
  justify-content: center;
  align-items: center;
  gap: 11.549px;
  border-radius: 115.49px;
  border: 1.155px solid var(--neutral-700, #3f444d);
}
.owner-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}
.owner-name {
  color: #21272a;
  text-align: center;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
}
.owner-type {
  color: #21272a;
  text-align: center;
  font-size: 20.788px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.talk-text {
  color: #21272a;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.logo-about {
  margin-top: 20px;
  display: flex;
  height: 73.913px;
  align-items: center;
  justify-content: center;
  gap: 9.239px;
}

.logo-text {
  color: #697077;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
}
.a_bottom-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.a_bottom-section {
  width: auto;
  height: 802px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.a_numer-container {
  margin-top: 50px;
  padding: 20px 90px;
}
.a_bottom-number {
  color: var(--neutral-900, #1b1f27);
  font-size: 27.682px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 2.215px;
}
.a_heading-container {
  width: 623px;
  margin-left: 130px;
}
.a_heading {
  color: #1b1f27;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 4px;
}
.a_and {
  color: #1b1f27;
  font-size: 50px;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 4px;
}
.a_subheading {
  margin-top: 30px;
  color: #3f444d;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.44px;
}
.a_awc {
  color: #3f444d;
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.44px;
}
.rectangle-container {
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  padding: 200px 130px;
}
.rectangle {
  width: 46.137px;
  height: 46.137px;
  background: #fff;
}
.a_image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.rec1 {
  width: 300px;
  height: 438.306px;
  margin-top: 50px;
  padding-right: 10px;
}
.rec2 {
  width: 300px;
  height: 438.306px;
  padding-right: 10px;
  margin-top: -200px;
}
@media (max-width: 320px) {
  .a_numer-container {
    padding: 0px 0px 0px 10px;
  }
  .a_heading-container {
    width: 400px;
    margin-left: 10px;
  }
  .a_heading {
    font-size: 25px;
  }
  .a_and {
    font-size: 25px;
  }
  .rectangle-container {
    margin-top: 60px;
    margin-left: -20px;
  }
  .a_swiper {
    left: 0px;
    width: 100%;
    height: 500px;
  }
  .a_subheading {
    font-size: 14px;
    width: 300px;
  }
  .a_awc {
    font-size: 14px;
  }
  .a_image-container {
    display: flex;
    flex-direction: row;
    margin-top: 90px;
    margin-bottom: 50px;
  }
  .a_bottom-container {
    display: flex;
    flex-direction: column;
  }
  .a_bottom-section {
    width: auto;
    height: auto;
  }
  .rec1 {
    width: 500px;
    height: 350px;
  }
  .rec2 {
    width: 500px;
    height: 350px;
    display: none;
  }
  .aboutus-image {
    width: 80.827px;
    height: 80.827px;
  }
  .owner-name {
    font-size: 18px;
  }
  .owner-type {
    font-size: 14px;
  }
  .talk-text {
    margin-top: -20px;
    font-size: 16px;
  }
  .logo-about {
    margin-top: -10px;
  }

  .logo-text {
    font-size: 16px;
  }
  .rectangle-container {
    gap: 6px;
    padding: 10px 30px;
  }
  .rectangle {
    width: 25px;
    height: 25px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .a_numer-container {
    padding: 0px 0px 0px 10px;
  }
  .a_heading-container {
    width: 400px;
    margin-left: 10px;
  }
  .a_heading {
    font-size: 25px;
  }
  .a_and {
    font-size: 25px;
  }
  .rectangle-container {
    margin-top: 60px;
    margin-left: -20px;
  }
  .a_swiper {
    left: 0px;
    width: 100%;
    height: 500px;
  }
  .a_subheading {
    font-size: 14px;
    width: 300px;
  }
  .a_awc {
    font-size: 14px;
  }
  .a_image-container {
    display: flex;
    flex-direction: row;
    margin-top: 90px;
    margin-bottom: 50px;
  }
  .a_bottom-container {
    display: flex;
    flex-direction: column;
  }
  .a_bottom-section {
    width: auto;
    height: auto;
  }
  .rec1 {
    width: 500px;
    height: 350px;
  }
  .rec2 {
    width: 500px;
    height: 350px;
    display: none;
  }
  .aboutus-image {
    width: 80.827px;
    height: 80.827px;
  }
  .owner-name {
    font-size: 18px;
  }
  .owner-type {
    font-size: 14px;
  }
  .talk-text {
    margin-top: -20px;
    font-size: 16px;
  }
  .logo-about {
    margin-top: -10px;
  }

  .logo-text {
    font-size: 16px;
  }
  .rectangle-container {
    gap: 6px;
    padding: 10px 30px;
  }
  .rectangle {
    width: 25px;
    height: 25px;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .a_numer-container {
    padding: 50px 30px 0px 30px;
  }
  .a_heading-container {
    width: 400px;
    margin-left: 40px;
  }
  .a_heading {
    font-size: 30px;
  }
  .a_and {
    font-size: 40px;
  }
  .rectangle-container {
    padding: 200px 10px;
    margin-top: 100px;
  }
  .rectangle {
    width: 25px;
    height: 25px;
  }
  .a_swiper {
    left: 35px;
    width: 90%;
    height: 500px;
  }
  .a_subheading {
    font-size: 14px;
    margin-top: 5px;
  }
  .a_awc {
    font-size: 14px;
  }
  .a_image-container {
    margin-left: -90px;
  }
  .rec1 {
    width: 500px;
    height: 350px;
    margin-top: 200px;
  }
  .rec2 {
    width: 500px;
    height: 350px;
    margin-top: -200px;
    margin-right: -100px;
    display: none;
  }
  .aboutus-image {
    width: 90.827px;
    height: 90.827px;
  }
  .owner-name {
    font-size: 20px;
  }
  .owner-type {
    font-size: 16px;
  }
  .talk-text {
    margin-top: -20px;
    font-size: 18px;
  }
  .logo-about {
    margin-top: -10px;
  }

  .logo-text {
    font-size: 16px;
  }
  .a_image-container-2 {
    display: none;
  }
}
@media (min-width: 769px) and (max-width: 1023px) {
  .a_numer-container {
    padding: 50px 30px 0px 30px;
  }
  .a_heading-container {
    width: 500px;
    margin-left: 40px;
  }
  .a_heading {
    font-size: 30px;
  }
  .a_and {
    font-size: 40px;
  }
  .rectangle-container {
    padding: 200px 40px;
    margin-top: 100px;
  }
  .a_swiper {
    left: 140px;
  }
  .a_subheading {
    font-size: 14px;
  }
  .a_awc {
    font-size: 14px;
  }
  .a_image-container {
    margin-left: -90px;
  }
  .rec1 {
    width: 500px;
    height: 350px;
    margin-top: 200px;
  }
  .rec2 {
    width: 500px;
    height: 350px;
    padding-right: 10px;
    margin-top: -200px;
    display: none;
  }
  .aboutus-image {
    width: 117.827px;
    height: 117.827px;
  }
  .owner-name {
    font-size: 24px;
  }
  .owner-type {
    font-size: 18px;
  }
  .talk-text {
    font-size: 20px;
  }
  .logo-about {
    margin-top: 10px;
  }

  .logo-text {
    font-size: 18px;
  }
  .a_image-container-2 {
    display: none;
  }
}
@media (min-width: 1023px) and (max-width: 1200px) {
  .a_numer-container {
    padding: 20px 40px;
  }
  .a_heading-container {
    width: 550px;
    margin-left: 50px;
  }
  .a_heading {
    font-size: 40px;
  }
  .a_and {
    font-size: 40px;
  }
  .rectangle-container {
    padding: 200px 40px;
  }
  .a_swiper {
    left: 160px;
  }
  .a_subheading {
    font-size: 16px;
  }
  .a_awc {
    font-size: 16px;
  }
  .a_image-container-2 {
    display: none;
  }
}
@media (min-width: 1201px) {
  .a_image-container-2 {
    display: none;
  }
}
.board {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.content-wrapper {
  height: 100%;
  width: 100%;
  max-width: 100rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}
.blue-line {
  height: 0.3rem;
  width: 10rem;
  background-color: rgb(79, 143, 226);
}

.board-slider {
  display: flex;
  text-align: center;
  margin-bottom: 50px;
  height: 500px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 80%;
}
.board-image-container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 30px;
}
.board-image {
  height: 150px;
  width: 150px;
  border-radius: 50%;
}
.b-text {
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 700;
  color: #225c7b;
  text-align: center;
}
.b-subtext {
  font-weight: 700;
  color: #225c7b;
  letter-spacing: 0.15rem;
  font-size: 20px;
  margin-top: 15px;
}
.b-para {
  margin-left: 45px;
  font-weight: 700;
  width: 90%;
}
@media (max-width: 320px) {
  .board-slider {
    display: flex;
    text-align: center;
    margin-bottom: 50px;
    height: auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: auto;
  }
  .quote-board {
    display: none;
  }
  .board-image {
    height: 120px;
    width: 120px;
    border-radius: 50%;
  }
  .board-image-container {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .b-text {
    font-size: 1.5rem;
  }
  .b-subtext {
    letter-spacing: 0rem;
    font-size: 16px;
    margin-top: 12px;
    margin-bottom: 0px;
  }
  .b-para {
    margin-left: 0px;
    font-weight: 700;
    width: 100%;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .board-slider {
    display: flex;
    text-align: center;
    margin-bottom: 50px;
    height: auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: auto;
  }
  .quote-board {
    display: none;
  }
  .board-image {
    height: 120px;
    width: 120px;
    border-radius: 50%;
  }
  .board-image-container {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .b-text {
    font-size: 1.5rem;
  }
  .b-subtext {
    letter-spacing: 0rem;
    font-size: 16px;
    margin-top: 12px;
    margin-bottom: 0px;
  }
  .b-para {
    margin-left: 0px;
    font-weight: 700;
    width: 100%;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .board-image {
    height: 120px;
    width: 120px;
    border-radius: 50%;
  }
  .board-image-container {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .b-text {
    font-size: 1.5rem;
  }
  .b-subtext {
    letter-spacing: 0rem;
    font-size: 16px;
    margin-top: 12px;
    margin-bottom: -5px;
  }
  .b-para {
    margin-left: 0px;
    font-weight: 700;
    width: 100%;
  }
}
@media (min-width: 769px) and (max-width: 1023px) {
  .board-image-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .b-para {
    margin-left: 30px;
    font-weight: 700;
    width: 90%;
  }
}
