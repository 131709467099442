.contact-us {
  width: auto;
  padding: 57px 412px;
  background: var(--neutral-0, #fff);
}
.form-heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.foam-heading-title {
  color: #1b1f27;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-top: 10%;
}
.foam-heading-subtitle {
  color: #1b1f27;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 200%;
}
.form-container {
  margin-top: 48px;
}
.foam-first-name,
.foam-last-name {
  gap: 4px;
}
.form-title {
  color: #1b1f27;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.foam-names {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 16px;
}
.first_name,
.last_name {
  margin-top: 10px;
  display: flex;
  height: 48px;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-bottom: 1px solid var(--secondary-100, #f1eff0);
  background: #e5f2f0;
  outline: none;
}
.email-container,
.phone-container,
.message-container {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

#email,
.phonenumber,
#message {
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-bottom: 1px solid var(--secondary-100, #f1eff0);
  background: #e5f2f0;
  outline: none;
}
.button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.send-button {
  margin-top: 10px;
  display: flex;
  height: 48px;
  width: 120px;
  padding: 16px 12px;
  justify-content: center;
  align-items: center;
  border: 2px solid #225c7b;
  background: #225c7b;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.5px;
}

.contact-footer {
  width: auto;
  padding: 80px;
  gap: 80px;
  border-bottom: 1px solid var(--cool-gray-20, #dde1e6);
  background: var(--default-white, #fff);
}
.content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
}
.cf-right-text {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
}
.section-left-text {
  display: flex;
  width: 384px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
  color: var(--cool-gray-90, #21272a);
}
.left-text-heading {
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  white-space: nowrap; /* Prevents the text from wrapping */
  overflow: hidden; /* Ensures no overflow */
  text-overflow: ellipsis; /* Optional: Adds ellipsis if the text overflows */
}
.left-small-text {
  color: var(--cool-gray-90, #21272a);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.cf-right-text {
  display: flex;
  align-items: flex-start;
  /* justify-content: flex-start; */
  flex: 1 0 0;
}
.cf-right-boxes {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  flex: 1 0 0;
  gap: 20px;
}
.cf-box-name {
  color: var(--cool-gray-90, #21272a);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
  letter-spacing: 0.5px;
}
.cf-box-mail {
  color: var(--cool-gray-90, #21272a);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
  letter-spacing: 0.5px;
}
.email-id {
  color: #0057ff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.5px;
  text-decoration-line: underline;
  cursor: pointer;
}
.cf-box-contact {
  display: flex;
  height: 48px;
  padding: 16px 0px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
}
.contact-title {
  color: var(--primary-60, #0f62fe);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
  letter-spacing: 0.5px;
}
.arrow {
  margin-top: 2px;
}
@media (max-width: 320px) {
  .contact-us {
    display: flex;
    padding: 26px 33.756px 33.781px 29px;
    flex-direction: column;
    align-items: flex-start;
    gap: 27.797px;
  }
  #email,
  #number,
  #message {
    width: auto;
    border-bottom: 1px solid #f1eff0;
    background: #e5f2f0;
    outline: none;
  }
  .foam-names {
    display: flex;
    flex-direction: column;
  }
  .content-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .cf-main-text {
    display: none;
  }
  .cf-right-text {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .contact-footer {
    width: auto;
    padding: 20px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .contact-us {
    display: flex;
    padding: 26px 33.756px 33.781px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 27.797px;
  }

  #email,
  #number,
  #message {
    width: auto;
    border-bottom: 1px solid #f1eff0;
    background: #e5f2f0;
    outline: none;
  }
  .foam-names {
    display: flex;
    flex-direction: column;
  }
  .content-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .cf-main-text {
    display: none;
  }
  .cf-right-text {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .contact-footer {
    width: auto;
    padding: 20px;
  }
  .foam-heading-title{
    margin-top: 20%;

  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .contact-us {
    display: flex;
    padding: 17px 212px;
    width: auto;
    justify-content: center;
    align-items: center;
  }
  .foam-heading-title{
    margin-top: 20%;

  }

  #email,
  #number,
  #message {
    width: auto;
    border-bottom: 1px solid #f1eff0;
    background: #e5f2f0;
    outline: none;
  }
  .foam-names {
    display: flex;
  }
  .content-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .cf-main-text {
    display: none;
  }
  .cf-right-text {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .contact-footer {
    width: auto;
    padding: 20px;
  }
}
@media (min-width: 769px) and (max-width: 1023px) {
  .contact-us {
    display: flex;
    padding: 17px 212px;
    width: auto;
    justify-content: center;
    align-items: center;
  }

  #email,
  #number,
  #message {
    width: auto;
    border-bottom: 1px solid #f1eff0;
    background: #e5f2f0;
    outline: none;
  }
  .foam-names {
    display: flex;
  }
  .content-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .cf-main-text {
    display: none;
  }
  .cf-right-text {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .contact-footer {
    width: auto;
    padding: 20px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .contact-us {
    display: flex;
    padding: 17px 212px;
    width: auto;
    justify-content: center;
    align-items: center;
  }

  #email,
  #number,
  #message {
    width: auto;
    border-bottom: 1px solid #f1eff0;
    background: #e5f2f0;
    outline: none;
  }

  .foam-names {
    display: flex;
  }
  .content-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .cf-main-text {
    display: none;
  }
  .cf-right-text {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .contact-footer {
    width: auto;
    padding: 20px;
  }
}
@media (min-width: 1201px) {
}

@media (max-width: 1200px) {
  .left-text-heading {
    font-size: 36px;
  }
}

@media (max-width: 992px) {
  .left-text-heading {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .left-text-heading {
    font-size: 24px;
  }
}

@media (max-width: 576px) {
  .left-text-heading {
    font-size: 18px;
  }
}

@media (max-width: 360px) {
  .left-text-heading {
    font-size: 16px;
  }
}
