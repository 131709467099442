.Features {
  display: flex;
  height: 173px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}
.Features-heading {
  color: #000;
  text-align: center;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}
.Features-subHeading {
  color: #2d2d2d;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
