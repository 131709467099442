.p_hero {
  display: flex;
  padding: 120px 55px 60px 55px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  align-self: stretch;
}
.p_hero-header {
  display: flex;
  width: auto;
  flex-direction: column;
  gap: 20px;
}
.p-header-heading {
  color: #2d2d2d;
  text-align: center;
  font-size: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -2.25px;
}
.p-header-subheading {
  color: #2d2d2d;
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.p_hero_CTA {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 18px;
}
.p_CTA-container {
  display: flex;
  align-items: flex-start;
  gap: 18px;
}
.p_CTA-button {
  display: flex;
  height: 60px;
  padding: 16px 50px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 20px;
  background: #009379;
}
.p_button-icon-container {
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
}
.p_button-icon {
  width: 20px;
  height: 20px;
  color: white;
}
.p_button-text {
  color: var(--white, #fff);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.p_button-icon-2-container {
  display: flex;
  height: 60px;
  align-items: center;
  border-radius: 20px;
  background: #009379;
}
.p_CTA-button-2 {
  display: flex;
  height: 60px;
  padding: 16px 50px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 20px;
  border: 1px solid #009379;
  background: #fff;
}
.p_button-text-2 {
  color: #009379;
  text-align: center;
  font-feature-settings: "liga" off;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
@media only screen and (max-width: 768px) {
  .p_button-text-2 {
    white-space: nowrap;
    overflow: visible;
    width: 100%;
    }
    .p-header-heading{
      font-size:60px ;
    }
   
   
    
}
