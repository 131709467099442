.CTA-section {
  display: flex;
  width: auto;
  padding: 120px 55px;
  justify-content: center;
  align-items: center;
  gap: 60px;
  background: #edfdf8;
}
.CTA-visual {
  width: 540px;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CTA-Header {
  gap: 40px;
  flex: 1 0 0;
}
.Ellipse {
  position: absolute;
  width: 378px;
  height: 378px;
  flex-shrink: 0;
  border-radius: 378px;
  background: #f2bfaf;
}
.Ellipse-icon {
  position: relative;
  margin-left: -50px;
}
.CTA-Header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  flex: 1 0 0;
}
.CTA-Header-Container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}
.CTA-Heading {
  align-self: stretch;
  color: #2d2d2d;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 65px */
  letter-spacing: -1px;
}
.CTA-SubHeading {
  color: #2d2d2d;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 31.5px */
  align-self: stretch;
}
@media only screen and (max-width: 768px) {
  .Ellipse {
    display: none; 
  }
}