.p_testimonials {
  display: flex;
  width: auto;
  padding: 120px 55px;
  gap: 60px;
  justify-content: center;
}
.p_testimonials-row {
  display: flex;
  width: auto;
  gap: 30px;
}
.p_testimonials-video {
  width: 407px;
  height: 546px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.video-testimonial {
  width: 376px;
  height: 486px;
}
.p_testimonials-cards-review {
  display: flex;
  padding: 40px 30px;
  align-items: center;
  gap: 30px;
  flex: 1 0 0;
  border-radius: 20px;
  border: 1px solid var(--cta-aqua-squeeze, #e5f4f2);
  background: #fff;
}
.p_testimonial-card-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.p_testimonial-client-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.p_client_image {
  width: 56px;
  height: 56px;
  border-radius: 56px;
  object-fit: cover;
}
.p_clint-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}
.p_client-name {
  color: var(--neutral-900, #111827);
  text-align: center;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 27.3px */
}
.p_clint-post {
  color: var(--neutral-700, #374151);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
}
.p_client-review {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}
.p_review {
  color: #2d2d2d;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}
@media only screen and (max-width: 768px) {
  .p_testimonials {
    padding: 50px 15px;
    gap: 30px;
    flex-direction: column;
    align-items: center;
  }
  .p_testimonials-row {
    flex-direction: column;
    gap: 20px;
    align-items: center;
    margin-top: 70%;
  }
  .p_testimonials-video {
    width: 100%;
    height: auto;
    margin-top: 20px;
  }
  
  .p_testimonials-cards-review {
    padding: 20px 15px;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: none;
  }
  .p_testimonial-card-info {
    gap: 20px;
   
  }
  .p_testimonial-client-info {
    gap: 10px;
  }
  .p_client-name {
    font-size: 18px;
  }
  .p_review {
    font-size: 14px;
  }
  .mySwiper_p_testimonials{
    width: 350px;
  }
}

