.p_video {
  display: flex;
  width: auto;
  padding: 0px 0px 12px 0px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.p_video-container {
  width: 895px;
  height: 546px;
  border-radius: 20px;
  background: linear-gradient(5deg, #fff 0%, #d2d2d2 42.35%, #fff 100%);
}
.video-frame {
  width: 895px;
  height: 546px;
  object-fit: contain;
}
/* @media (max-width: 320px) {
  .video-frame{
    width: 100vw;
    height: 100vh;
  }
}

@media (min-width: 321px) and (max-width: 480px) {
  .video-frame {
    width: 100px;
    height: auto;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .video-frame {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .video-frame {
    width: 100%;
    height: auto;
  }
} */
