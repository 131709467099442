.meet-people {
  padding: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.meet-people-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mp-image {
  height: 300px;
  width: auto;
  margin-right: 2rem;
}
.mp-heading {
  font-size: 32px;
  font-weight: 700;
}
.mp-subheading {
  font-size: 18px;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.mp-span {
  margin-bottom: 20px;
  font-size: 18px;
  margin-top: -10px;
}
.mp-button {
  margin-top: 10px;
  color: #fff;
  font-size: 16px;
  line-height: 100%;
  border-radius: 25px;
  letter-spacing: 0.5px;
  background: linear-gradient(148deg, #066042 0%, #00d890 100%);
}
@media (max-width: 320px) {
}
@media (min-width: 320px) and (max-width: 700px) {
  .meet-people {
    padding: 1rem;
  }
  .mp-image {
    height: 350px;
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0rem;
  }
  .meet-people-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .mp-heading {
    font-size: 32px;
  }
  .mp-subheading {
    font-size: 22px;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
  }
  .mp-span {
    font-size: 22px;
  }
  .mp-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 700px) and (max-width: 738px) {
  .mp-image {
    height: 300px;
    width: 300px;
    margin-right: 1.5rem;
  }
  .meet-people {
    padding: 1.8rem;
  }

  .mp-heading {
    font-size: 20px;
  }
  .mp-subheading {
    font-size: 18px;
    padding-top: 0.5rem;
    padding-bottom: 1.5rem;
  }
}
@media (min-width: 739px) and (max-width: 852px) {
  .mp-image {
    height: 300px;
    width: 300px;
    margin-right: 1.5rem;
  }
  .meet-people {
    padding: 1.8rem;
  }

  .mp-heading {
    font-size: 20px;
  }
  .mp-subheading {
    font-size: 18px;
    padding-top: 0.5rem;
    padding-bottom: 1.5rem;
  }
}
@media (min-width: 853px) and (max-width: 968px) {
  .mp-image {
    height: 300px;
    width: 350px;
    margin-right: 2rem;
  }
  .meet-people {
    padding: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mp-heading {
    font-size: 22px;
  }
  .mp-subheading {
    font-size: 18px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
@media (min-width: 969px) and (max-width: 1023px) {
  .meet-people {
    padding: 3rem;
  }
  .meet-people-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mp-heading {
    font-size: 25px;
  }
  .mp-subheading {
    font-size: 16px;
  }
}
.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.video-modal-content {
  position: relative;
  width: 80%;
  height: 45%;
  max-width: 500px;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  cursor: pointer;
}

close-button:hover {
  color: #00ac73; 
}


.apna-konnect-list {
  list-style-type: none;
  padding-left: 0;
}

.apna-konnect-list li {
  margin-bottom: 10px;
}

.apna-konnect-list li strong {
  font-weight: bold;
}
