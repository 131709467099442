/* Navbar */
.nav {
  position: fixed;
  width: 100%;
  display: flex;
  padding: 10px 20px; /* Adjust padding for better spacing */
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  background-color: #cae85d;
  z-index: 1000;
  margin-bottom: 10px;
}

/* Navbar image */
.nav-logo {
  display: flex;
  align-items: center; /* Center align the logo */
  gap: 10px;
  height: auto;
  width: auto;
  padding: 5px; /* Add padding for better spacing */
  background-color: #cae85d;
  border-radius: 20px;
}

.nav-image {
  cursor: pointer;
  max-width: 100%; /* Ensure image doesn't exceed container width */
  height: auto; /* Maintain aspect ratio */
}

/* Navbar menu Items */
.nav-items {
  display: none; /* Hide menu items by default on small screens */
}

.nav.scrolled {
  background-color: white; /* Change background color to white when scrolled */
}

/* Hamburger menu icon */
.container {
  display: inline-block;
  cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 4px 0;
  transition: 0.4s;
}

.change .bar1 {
  transform: translate(0, 8px) rotate(-45deg);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  transform: translate(0, -8px) rotate(45deg);
}

/* Dropdown menu */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #393939;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 40px; /* Adjust margin-top for better alignment */
}

.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
  color: #0a0d14;
}

.show {
  display: block;
}

/* Navbar buttons */
.nav-buttons {
  display: flex;
  gap: 10px;
}

.nav-button2 {
  color: var(--secondary-800, #83a295);
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.5px;
}

/* Media queries */
@media (max-width: 768px) {
  .nav {
    padding: 10px; /* Adjust padding for better spacing */
  }

  .nav-logo {
    margin-left: 10px; /* Adjust margin for better alignment */
  }

  .nav-items {
    display: none; /* Hide menu items on small screens */
  }

  .container {
    display: block; /* Show hamburger menu icon */
  }
}
