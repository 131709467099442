.frame-1 {
  width: auto;
  height: 705px;
  background: #fff;
  margin-left: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.frame-1-Left {
  flex-direction: row;
}
.f-1-Heading {
  color: var(--neutral-900, #1b1f27);
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 4px;
  margin-bottom: 20px;
}
.your {
  color: var(--neutral-900, #1b1f27);
  font-size: 50px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 4px;
}
.AWC {
  color: var(--neutral-700, #3f444d);
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.6px;
  margin-left: 20px;
}
.f-1-subHeading {
  color: var(--neutral-700, #3f444d);
  font-family: Lewinsky;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.6px;
}
.frame-1-Right {
  width: 200%;
  height: 200%;
  margin-top: 50%;
}
.shadow {
  width: 624px;
  height: 634.146px;
  background: url("../../../Assets/Premium Membership/shadow.png");
  padding: 1.27px 139.2px 117px 113.51px;
  --tw-shadow: none;
}
.frame-main {
  position: absolute;
  width: 371.293px;
  height: 515.878px;
  background: url("../../../Assets/Premium Membership/main.png");
}
.mobile-screen {
  width: 340.854px;
  height: 483.22px;
  margin-left: 20px;
  margin-top: 10px;
  background: url("../../../Assets/Premium Membership/Flying iPhone.png");
}
.frame-2 {
  width: auto;
  height: 705px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 90px 0px 90px;
}
.frame-2-Left {
  flex-direction: row;
}
.f-2-Heading {
  color: var(--neutral-900, #1b1f27);
  text-align: right;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 4px;
  margin-bottom: 20px;
}
.f-2-subHeading {
  color: var(--neutral-700, #3f444d);
  text-align: right;
  font-family: Lewinsky;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.6px;
  width: 576px;
}
.of {
  color: var(--neutral-900, #1b1f27);
  font-size: 50px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 4px;
}
.frame-2-Right {
  width: 624px;
  height: 634.146px;
  margin-top: 56px;
}
.shadow-2 {
  width: 524px;
  height: 634.146px;
  background: url("../../../Assets/Premium Membership/shadow.png");
  margin-top: 50px;
  --tw-shadow: none;
}
.frame-main-2 {
  position: absolute;
  width: 371.293px;
  height: 515.878px;
  background: url("../../../Assets/Premium Membership/main.png");
}
.mobile-screen-2 {
  width: 340.854px;
  height: 483.22px;
  background: url("../../../Assets/Premium Membership/Flying iPhone.png");
}
.mobile-f2-heading{
  display: none;
}
.f-3-subHeading{
  display: none;
}
@media only screen and (max-width: 768px) {
  .frame-1 {
    flex-direction: column;
    margin-top: 70%;
    align-items: center;
  }
  

  .frame-2 {
    flex-direction: column;
    margin-top: 100%;
    order: inherit;
    align-items: center;
  }

  .frame-2-Left {
    order: 1; 
    display: flex;
    flex-direction: column;
  }

  .frame-2-Right {
    order: 2; 
    display: flex;
    justify-content: center;
  }
  .f-2-Heading{
    display: none
  }
  .f-2-subHeading{
    display: none;
  }
  .f-3-subHeading{
    display: block;
    color: var(--neutral-700, #3f444d);
    font-family: Lewinsky;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.6px

  }
  .mobile-f2-heading {
    display: block;
    color: var(--neutral-900, #1b1f27);
    text-align: center;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 4px;
    margin-bottom: 0px;
    margin-top: 50%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-right: 2%;
  }
 
}

