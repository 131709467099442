.Hero {
  /* background: linear-gradient(309deg, #ccd7b5 0%, rgba(204, 215, 181, 0) 100%); */
  height: auto;
}
.hero-background {
  background-color: #fff;
  height: 10vh;
}
.Hero-Container {
  margin-top: -300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px; /* Add padding */
}

.Hero-Left {
  display: flex;
  width: 566px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 45px;
}
.Left-Heading {
  color: var(--neutral-900, #1b1f27);
  font-size: 54px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
}
.hero-span {
  color: var(--neutral-900, #1b1f27);
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
}
.Left-text {
  width: 470px;
  color: var(--neutral-900, #1b1f27);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.Left-buttons {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}
.Left-button-1 {
  display: flex;
  height: 56px;
  width: 180px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  /* background: linear-gradient(148deg, #066042 0%, #00d890 100%); */
  /* background: linear-gradient(95.96deg, #066042 59.98%, #00D890 112.97%); */
  /* background: linear-gradient(275.57deg, #066042 45.32%, #00D890 101.98%); */

  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.5px;
  gap: 10px;

}

.Left-button-2 {
  /* color: var(--success-700, #00ac73); */
  color:#066042;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.5px;
  display: flex;
  height: 56px;
  width: 185px;
  padding: 16px;
  justify-content: center;
  align-items: center;
border: 2px solid ;
border-image: linear-gradient(180deg, #066042 0%, rgba(0, 255, 170, 0.57) 100%);
border-image-slice: 1;




  /* border-image: linear-gradient(to right, #066042, #00ffaa91) 1; */
}
.Hero-Right-Image {
  width: 813px;
  height: 561px;
}
.Decore-Image {
  
  z-index: 2; 
  position: absolute;
}
.Hero-postal {
  height: auto;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hp-container {
  padding: 10px;
  text-align: left;
  color: #4c4556;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.45;
}
.hp-text {
  margin-bottom: 10px;
}
.hp-foam {
  display: flex;
  justify-content: center;
  align-items: center;
}
.hp-text-container {
  align-items: center;
  background: #f4f4f6;
  border: 1px solid #0000;
  border-radius: 1rem;
  display: flex;
  justify-content: space-between;
  padding: 0.25rem;
  position: relative;
  transition: 0.3s ease-in-out;
}
.hp-input {
  background: none;
  caret-color: #201649;
  color: #201649;
  font-family: inherit;
  height: 56px;
  padding: 1.25rem;
  max-width: 400px;
}
.hp-button-container {
  background-color: #ff9dd2;
  border-radius: 0.75rem;
  color: #201649;
  font-size: 18px;
  font-weight: 600;
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* @media (min-width: 100px) and (max-width: 319px) {
  .Hero {
    padding: 20px 0px 20px 15px;
    width: auto;
  }

  .Left-text {
    max-width: 300px;
  }

  .Left-Heading {
    font-size: 25px;
  }

  .Hero-Right-Image {
    display: none;
  }
} */
@media (max-width: 320px) {
  .Hero {
    padding: 20px 0px 20px 8px;
  }

  .Left-text {
    width: auto;
  }
  .Left-Heading {
    font-size: 18px;
  }
  .hero-span {
    font-size: 28px;
  }
  .Hero-Right-Image {
    display: none;
  }
  .Left-buttons {
    display: flex;
    flex-direction: column;
  }
  .Left-button-1 {
    width: 130px;
  }

  .Left-button-2 {
    width: 120px;
  }
  .hp-input {
    max-width: 200px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .Hero {
    padding: 20px 0px 20px 10px;
  }

  .Left-text {
    max-width: 305px;
  }
  .Left-Heading {
    font-size: 34px;
  }
  .hero-span {
    font-size: 34px;
  }
  .Hero-Right-Image {
    display: none;
  }
  .Left-buttons {
    gap: 8px;
    /* margin-left: 20px; */
  }
  .Left-button-1 {
    width: 130px;
  }

  .Left-button-2 {
    width: 120px;
  }
  .Hero-postal {
    width: 100%;
  }
  .hp-container {
    padding: 0px;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .Hero {
    padding: 20px 0px 20px 40px;
    /* display: flex; */
  }

  .Left-text {
    max-width: 560px;
    font-size: 25px;
  }
  .Left-Heading {
    font-size: 40px;
    letter-spacing: 0.2rem;
  }
  .Hero-Right-Image {
    display: none;
  }
}
@media (min-width: 769px) and (max-width: 1023px) {
  .Hero {
    padding: 20px 0px 20px 20px;
  }
  .Left-text {
    max-width: 360px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
}
@media (min-width: 1201px) {
}
