.back-color {
    background-color: #f1f4ec;
  }
 
  .marginnar{
    margin-top: 30px;
  }
  .rc-header {
    text-align: center;
  }
  .rc-h1 {
    /* margin-top: 20px; */
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 75px;
    color: #225c7b;
  }
  .rc-h3 {
    margin-top: 60px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #abb497;
    text-transform: uppercase;
  }
  .rc-p {
    margin-top: 30px;
    margin-bottom: 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
  