.Acc-Container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
.Acc-button {
  display: flex;
  height: 50px;
  width: 160px;
  justify-content: center;
  align-items: center;
  /* border: 2px solid var(--success-700, #00ac73); */
  /* background: var(--success-700, #00ac73); */
  background-color: #cae85d;
  /* color: var(--default-white, #fff); */
  color: black;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 20px */
  letter-spacing: 0.5px;
}
.radio2 {
  border: 0.5px solid #dde1e6;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
}
