.user-groups {
  padding-top: 30px;
  margin-top: 50px;
  background-color: #f1f4ec;
  height: auto;
}
.user-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.user-title {
  color: #5b9092;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.user-text {
  color: #1b1f27;
  text-align: center;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}
.paragraph {
  display: flex;
  width: auto;
  height: 130px;
  padding: 43px 80px 0px 80px;
  flex-direction: column;
  align-items: center;
  gap: 48px;
}
.user-paragraph {
  color: #1b1f27;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.user-main {
  display: flex;
  width: auto;
  padding: 80px;
  flex-direction: column;
  align-items: center;
  gap: 64px;
}
.user-boxes {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
}
.user-box {
  display: flex;
  width: 500px;
  padding: 24px;
  height: 230px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border: 1px solid var(--cool-gray-20, #dde1e6);
  background: var(--default-white, #fff);
}
.box1-heading {
  color: var(--neutral-900, #1b1f27);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
}
.box1-text {
  color: var(--neutral-900, #1b1f27);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.user-icons {
  display: flex;
  flex-wrap: wrap;
  width: auto;
  padding: 80px;
  align-items: center;
  justify-content: space-evenly;
  gap: 64px;
}
.user-icon {
  display: flex;
  align-items: center;
  gap: 12px;
}
.icon {
  width: 30.4px;
  height: 30px;
}
.icon-name {
  color: var(--cool-gray-60, #697077);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
}
@media (max-width: 320px) {
  .user-text {
    font-size: 30px;
  }
  .paragraph {
    padding: 20px;
    height: auto;
  }

  .user-main {
    padding: 40px;
    gap: 40px;
  }

  .user-box {
    width: 100%;
    height: fit-content;
  }
  .user-boxes {
    display: flex;
    flex-direction: column;
  }
  .user-paragraph {
    font-size: 22px;
    letter-spacing: 0.019rem;
  }
  .icon {
    width: 24px;
    height: 24px;
  }
  .icon-name {
    font-size: 15px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .user-text {
    font-size: 30px;
  }
  .paragraph {
    padding: 20px;
    height: auto;
  }

  .user-main {
    padding: 40px;
    gap: 40px;
  }

  .user-box {
    width: 100%;
    /* padding: 20px; */
    height: fit-content;
  }

  .user-boxes {
    padding: 15px;
    gap: 10px;
  }
  .user-boxes {
    display: flex;
    flex-direction: column;
  }
  .user-paragraph {
    font-size: 22px;
    letter-spacing: 0.019rem;
  }
  .icon {
    width: 24px;
    height: 24px;
  }
  .icon-name {
    font-size: 15px;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .user-text {
    font-size: 30px;
  }
  .paragraph {
    padding: 20px;
    height: auto;
  }

  .user-main {
    padding: 40px;
    gap: 40px;
  }

  .user-box {
    width: 100%;
    padding: 20px;
    height: 300px;
  }
  .user-boxes {
    padding: 5px;
    gap: 15px;
  }
  .user-paragraph {
    font-size: 22px;
    letter-spacing: 0.019rem;
  }
  /* .user-icons {
    padding: 40px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  } */

  .icon {
    width: 24px;
    height: 24px;
  }

  .icon-name {
    font-size: 20px;
  }
}
@media (min-width: 769px) and (max-width: 1023px) {
  .user-text {
    font-size: 30px;
  }
  .paragraph {
    padding: 20px;
    height: auto;
  }

  .user-main {
    padding: 40px;
    gap: 40px;
  }

  .user-box {
    width: 100%;
    padding: 20px;
    height: 300px;
    justify-content: center;
  }

  /* .user-icons {
    padding: 40px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  } */

  .icon {
    width: 24px;
    height: 24px;
  }

  .icon-name {
    font-size: 20px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .paragraph {
    padding: 30px 80px;
    height: 130px;
  }

  .user-main {
    padding: 80px;
  }

  .user-box {
    width: 500px;
    padding: 24px;
  }
}
@media (min-width: 1201px) {
}

@media (max-width: 320px) {
}
@media (max-width: 480px) {
}
@media (max-width: 576px) {
}
@media (min-width: 577px) and (max-width: 998px) {
}
@media (min-width: 1025px) {
}
